@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Anta&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&display=swap");

@layer base {
  :root {
    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;
    --border: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;
  }

  .dark {
    --card: 222.2 84% 4.9%;
    --card-foreground: 210 40% 98%;
    --border: 217.2 32.6% 17.5%;
    --ring: 212.7 26.8% 83.9%;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: transparent !important;
  user-select: none;
  -webkit-user-select: none;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: Anta, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif !important;
}

@font-face {
  font-family: "anta";
  src: url("../src/fonts/Anta-Regular.ttf") format("truetype");
}

@supports (scrollbar-width: thin) {
  html {
    scrollbar-width: none;
  }
}
@supports not (scrollbar-width: thin) {
  html {
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

html {
  background-color: transparent;
}

@supports (scroll-behavior: smooth) {
  html {
    scroll-behavior: smooth;
  }
}
@supports not (scroll-behavior: smooth) {
  html {
    scroll-behavior: auto;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.hoverAnimation:hover {
  color: rgb(12, 24, 75);
}

.hoverBorderAnimation:hover {
  text-shadow: 0 0 1px rgb(45, 59, 121), 0 0 5px rgb(45, 59, 121), 0 0 10px rgb(45, 59, 121), 0 0 20px rgb(45, 59, 121);
}

#avatar {
  --b: 2.5px; /* the border thickness*/
  --g: 8px; /* the gap */
  --c: #4ecdc4; /* the color */

  aspect-ratio: 1;
  border-radius: 50%;
}

#avatar:hover {
  outline: var(--b) solid var(--c);
  outline-offset: var(--g);
}

main {
  max-width: 100vw;
}
